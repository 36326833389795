<template>
    <li class="faq-wrapper" :class="{ show: contentVisible }">
        <button @click="toggleContent" :aria-expanded="contentVisible ? 'true' : 'false'">
            <h4 class="question">
                {{ data.templateContentFields.displayTitle }}
            </h4>
        </button>
        <transition name="fade">
            <div class="answer" v-if="contentVisible && data.templateContentFields.contentFirst"
                v-html="data.templateContentFields.contentFirst"></div>
        </transition>
    </li>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            contentVisible: false,
        };
    },
    methods: {
        toggleContent() {
            this.contentVisible = !this.contentVisible;
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";

// .column-title {
// 	color: #fff;
// }
// .dark .column-title {
// 	color: $black;
// }
</style>
